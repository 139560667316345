import { createStore } from 'vuex'

import frontApp from '../modules/proceso-electoral/store/frontApp'

const store = createStore({
  modules: {
      frontApp
  }
})


export default store