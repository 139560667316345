export default {

    name:'municipios',
    component: () => import(/* webpackChunkName: "municipios" */ '@/modules/municipios/layouts/MunicipioLayout.vue'),
    children: [
        {
            path: '',
            name: 'home-municipios',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/municipios/views/MenuIndicadores.vue'),
        }
    ]
}