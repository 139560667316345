export default {

    name: 'ejercicio-cargo',
    component: () => import(/* webpackChunkName: "daybook" */ '@/modules/ejercicio-cargo/layouts/Ejercicio-Cargo-Layout.vue'),
    children: [
        {
            path:'',
            name:'home-ejercicio-cargo',
            component: () => import( '@/modules/ejercicio-cargo/views/Home-Ejercicio-Cargo.vue')
        },
        {
            path:'diputaciones/grupos-parlamentarios/:distrito',
            name:'diputacion-grupos-parlamentarios',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Grupos-Parlamentarios.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/nivel-educativo/:distrito',
            name:'diputacion-nivel-educativo',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Nivel-Educativo.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/junta-coordinacion-politica/:distrito',
            name:'diputacion-junta-coordinacion-politica',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Junta-CoordinacionPolitica.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/mesa-directiva/:distrito',
            name:'diputacion-mesa-directiva',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Mesa-Directiva.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputacione/comisiones/:distrito',
            name:'diputacion-comisiones',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Comisiones.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/asistencias/:distrito',
            name:'diputacion-asistencias',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Asistencias.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/asistencias/:distrito',
            name:'diputacion-asistencias',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Asistencias.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/participacion-tribuna/:distrito',
            name:'diputacion-participacion-tribuna',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Participacion-Tribuna.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/junta-coordinacion-politica/:distrito',
            name:'diputacion-junta-coordinacion-politica',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Junta-CoordinacionPolitica.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/propuesta-orden-dia/:distrito',
            name:'diputacion-propuestas-orden-dia',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Propuesta-OrdenDia.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/exhortos/:distrito',
            name:'diputacion-exhortos',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Exhortos.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/comparecencias/:distrito',
            name:'diputacion-comparecencias',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Comparecencias.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/iniciativa-ley/:distrito',
            name:'diputacion-iniciativas-ley',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Iniciativa-Ley.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/redes-sociales/:distrito',
            name:'diputacion-redes-sociales',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Redes-Sociales.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/nombramientos-realizados/:distrito',
            name:'diputacion-nombramientos-realizados',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Nombramientos-Realizados.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/licencias-permisos/:distrito',
            name:'diputacion-permisos-licencias',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Licencias-Permisos.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/violencia-razon-genero/:distrito',
            name:'diputacion-violencia-razon-genero',
            component: () => import( '@/modules/ejercicio-cargo/views/diputaciones/Violencia-RazonGenero.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        
        // municipios ejercicio del cargo 

        {
            path:'municipios/protesta/:municipio',
            name:'municipios-protesta',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Municipio-Protesta.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/integracion-comisiones/:municipio',
            name:'municipios-integracion-comisiones',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Integracion-Comisiones.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/sesiones-cabildo/:municipio',
            name:'municipios-sesiones-cabildo',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Sesiones-Cabildo.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/redes-sociales/:municipio',
            name:'municipios-redes-sociales',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Redes-Sociales.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/auditorias/:municipio',
            name:'municipios-auditorias',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Auditorias.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/informes-anuales/:municipio',
            name:'municipios-informes-anuales',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Redes-Sociales.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/renuncias/:municipio',
            name:'municipios-renuncias',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Renuncias.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/postulaciones/:municipio',
            name:'municipios-postulaciones',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Postulaciones.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/licencias-permisos/:municipio',
            name:'municipios-licencias-permisos',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Licencias-Permisos.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/informes-anuales/:municipio',
            name:'municipios-informes-anuales',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Informe-Anual.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/nombramientos-cabildo/:municipio',
            name:'municipios-nombramientos-cabildo',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Nombramiento-Cabildo.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/violencia-razon-genero/:municipio',
            name:'municipios-violencia-razon-genero',
            component: () => import( '@/modules/ejercicio-cargo/views/municipios/Violencia-RazonGenero.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        
        
    ]


}