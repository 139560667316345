export default {

    name:'proceso-electoral',
    component: () => import(/* webpackChunkName: "proceso-electoral" */ '@/modules/proceso-electoral/layouts/Proceso-electoral-layout.vue'),
    children:[ 
        {
            path: '',
            name: 'home-proceso-electoral',
            component: () => import('@/modules/proceso-electoral/views/Proceso-electoral-home.vue')
        },
        {
            path:'universo-aspirantes',
            name:'dip-universo-aspirantes',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-universo-aspirantes.vue')
        },
        {
            path:'lista-nominal',
            name:'dip-lista-nominal',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-lista-nominal.vue')
        },
        {
            path:'postulaciones-internas',
            name:'dip-postulaciones-internas',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-postulaciones-internas.vue')
        },
        {
            path:'candidaturas',
            name:'dip-candidaturas',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-candidaturas.vue')
        },
        {
            path:'bloques-competitividad',
            name:'dip-bloques-competitividad',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-bloques-competitividad.vue')
        },
        {
            path:'diputaciones-electas',
            name:'dip-diputaciones-electas',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-diputaciones-electas.vue')
        },
        {
            path:'titulares-suplentes',
            name:'dip-titulares-suplentes',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-titulares-suplentes.vue')
        },
        {
            path:'distritos-mayor-poblacion',
            name:'dip-distritos-mayor-poblacion',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/diputaciones/Dip-distritos-mayor-poblacion.vue')
        },

        // Proceso electoral municipios
        {
            path:'/municipios/aspirantes',
            name:'municipio-aspirantes',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Aspirantes.vue')
        },
        {
            path:'/municipios/lista-nominal',
            name:'municipio-lista-nominal',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Lista-Nominal.vue')
        },
        {
            path:'/municipios/postulaciones-internas',
            name:'municipio-postulaciones-internas',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Postulaciones-Internas.vue')
        },
        {
            path:'/municipios/candidaturas',
            name:'municipio-candidaturas',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Candidaturas.vue')
        },
        {
            path:'/municipios/bloques-competitividad',
            name:'municipio-bloques-competitividad',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Bloques-Competitividad.vue')
        },
        {
            path:'/municipios/resultados-elecciones',
            name:'municipio-resultados-elecciones',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Resultados-Elecciones.vue')
        },
        {
            path:'/municipios/presidencia-mayor-poblacion',
            name:'municipio-presidencia-mayor-poblacion',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/proceso-electoral/views/municipios/Presidencia-MayorPoblacion.vue')
        },


    ]

}