import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'

import axios from 'axios'
import VueAxios from 'vue-axios'

import './styles/styles.scss'

createApp(App)
    .use(store)
    .use(router)
    .use(VueChartkick)
    .use(VueAxios,axios)
    .mount('#app')


    axios.defaults.baseURL = 'https://site--api-paimef--tvzdhp2q7p8g.code.run/api'