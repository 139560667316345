export default {

    name: 'indicadores',
    component: () => import(/* webpackChunkName: "Indicadores" */ '@/modules/indicadores/layouts/IndicadoresLayout.vue'),
    children: [
        {
            path:'',
            name: 'home-indicadores',
            component: () => import( '@/modules/indicadores/views/Home.vue')
        },
        {
            path:'diputaciones/proceso-electoral',
            name:'diputaciones-proceso-electoral',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/indicadores/views/DipPE.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'diputaciones/ejercicio-cargo',
            name:'diputaciones-ejercicio-cargo',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/indicadores/views/DipEC.vue'),
            props:( route ) => {
                return {
                    distrito: route.params.distrito
                }
            }
        },
        {
            path:'municipios/proceso-electoral',
            name:'municipios-proceso-electoral',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/indicadores/views/MunPE.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        {
            path:'municipios/ejercicio-cargo',
            name:'municipios-ejercicio-cargo',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/indicadores/views/MunEC.vue'),
            props:( route ) => {
                return {
                    municipio: route.params.municipio
                }
            }
        },
        
    ]
    
}
