import { createRouter, createWebHistory } from 'vue-router'

import diputacionesRouter from '../modules/diputaciones/router'
import indicadoresRouter from '../modules/indicadores/router'
import municipiosRouter from '../modules/municipios/router'
import procesoElectoralRouter from '../modules/proceso-electoral/router'
import ejercicioCargo from '../modules/ejercicio-cargo/router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path:'/diputaciones',
    ...diputacionesRouter
  },
  {
    path:'/indicadores',
    ...indicadoresRouter

  },
  {
    path:'/municipios',
    ...municipiosRouter
  },
  {
    path:'/proceso-electoral',
    ...procesoElectoralRouter
  },
  {
    path:'/ejercicio-cargo',
    ...ejercicioCargo
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
