export default {

    name: 'diputaciones',
    component: () => import(/* webpackChunkName: "daybook" */ '@/modules/diputaciones/layouts/DiputacionesLayout.vue'),
    children: [
        {
            path: '',
            name: 'home-diputaciones',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/diputaciones/views/MenuIndicadores.vue'),
        },
        {
            path:'/diputaciones/menu-indicadores/proceso-electoral',
            name:'dip-menu-indicadores',
            component: () => import(/* webpackChunkName: "indicadores-nombre" */ '@/modules/diputaciones/views/MenuIndicadores.vue'),
        },
        
    ]
}


